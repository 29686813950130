import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import MarkdownIt from "markdown-it";
import moment from "moment";

import {
  PENDING_REVIEW,
  ACCEPTED,
  map as evidenceStatusMap,
  DISMISSED,
} from "../EvidenceStatus";

import { amber, green } from "@mui/material/colors";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {
  Button,
  IconButton,
  Popover,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Icon,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const markdown = new MarkdownIt();

const useStyles = makeStyles((theme) => ({
  retrievalStatus: {
    display: "flex",
  },
  media: {
    position: "relative",
    paddingBottom: "56.25%",
    overflow: "hidden",
  },
  mediaImage: {
    cursor: "pointer",
    marginBottom: theme.spacing(1),
  },
  videoDownload: {
    textAlign: "center",
    paddingBottom: 0,
  },
  mediaVideo: {
    padding: 0,
  },
  html5Video: {
    maxWidth: "100%",
  },
  pendingReviewChip: {
    backgroundColor: amber[500],
  },
  acceptedChip: {
    backgroundColor: green[500],
  },
  leftIcon: {
    marginRight: theme.spacing(2),
  },
  videoCam: {
    fontSize: 60,
    color: theme.palette.divider,
  },
}));

const EvidenceItem = ({ attachment, updateEvidence, editable, classes }) => {
  const [updating, setUpdating] = React.useState(false);

  const setEvidenceStatus = (status) => {
    setUpdating(true);

    return updateEvidence({
      key: attachment.key,
      values: {
        description: attachment.description,
        status,
      },
    })
      .then(() => setUpdating(false))
      .catch((error) => {
        console.error(error);
        setUpdating(false);
      });
  };

  const handleAcceptClick = () => {
    return setEvidenceStatus(ACCEPTED);
  };

  const handleDismissClick = () => {
    return setEvidenceStatus(DISMISSED);
  };

  return (
    <Card className={classes.card}>
      {attachment.type === "PICTURE" && (
        <CardMedia
          onClick={() => window.open(attachment.source.src)}
          className={classNames(classes.media, classes.mediaImage)}
          image={attachment.source.src}
        />
      )}
      {attachment.type === "VIDEO" && (
        <Fragment>
          {attachment.source.contentType === "video/webm" ||
          attachment.source.contentType === "video/mp4" ? (
            <CardContent className={classes.mediaVideo}>
              <video className={classes.html5Video} controls>
                Sorry, your browser does not support embedded videos
                <source
                  src={attachment.source.src}
                  type={attachment.source.contentType}
                />
              </video>
            </CardContent>
          ) : (
            <CardContent className={classes.videoDownload}>
              <div>
                <Icon className={classes.videoCam}>videocam</Icon>
              </div>
              <div>
                <Button
                  size="small"
                  component="a"
                  target="_blank"
                  href={attachment.source.src}
                >
                  <Icon className={classes.leftIcon}>cloud_download</Icon>
                  Download
                </Button>
              </div>
            </CardContent>
          )}
        </Fragment>
      )}
      <CardContent>
        {attachment.status === PENDING_REVIEW && editable && (
          <>
            <Tooltip
              title="Keep this evidence as part of the incident"
              enterDelay={300}
            >
              <Button
                color="success"
                size="small"
                variant="contained"
                disabled={updating}
                onClick={handleAcceptClick}
                sx={{ marginRight: 2 }}
              >
                Keep
              </Button>
            </Tooltip>
            <Tooltip
              title="This evidence is not relevant - it can be retrieved later if needed"
              enterDelay={300}
            >
              <Button
                color="error"
                size="small"
                variant="contained"
                disabled={updating}
                onClick={handleDismissClick}
              >
                Dismiss
              </Button>
            </Tooltip>
          </>
        )}
        {(attachment.status !== PENDING_REVIEW || !editable) && (
          <Chip
            label={evidenceStatusMap(attachment.status)}
            className={classNames(classes.chip, {
              [classes.acceptedChip]: attachment.status === ACCEPTED,
              [classes.pendingReviewChip]: attachment.status === PENDING_REVIEW,
            })}
          />
        )}
        {attachment.description && (
          <div
            dangerouslySetInnerHTML={{
              __html: markdown.render(attachment.description),
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};

const InfoPopover = ({ request }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <IconButton
        color="info"
        aria-label="Show request info"
        onClick={handleClick}
        sx={{ padding: 0 }}
      >
        <InfoIcon fontSize="small" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>
          Requested by {request.createdByUser?.name ?? "Driver"} on{" "}
          {moment(request.createdAt).format("lll")}
        </Typography>
      </Popover>
    </>
  );
};

const IncidentEvidence = ({
  attachedEvidence,
  updateEvidence,
  footageRequests,
  showDismissed,
  editable,
}) => {
  const classes = useStyles();
  const [onScreenAttachedEvidence, setOnScreenAttachedEvidence] =
    React.useState([]);

  React.useEffect(() => {
    const toDisplay = attachedEvidence.filter(
      (attachment) => showDismissed || attachment.status !== DISMISSED
    );

    setOnScreenAttachedEvidence(toDisplay);
  }, [attachedEvidence, showDismissed]);

  return (
    <div>
      {Boolean(footageRequests.length) && (
        <div>
          <Box component="ul" sx={{ padding: 0 }}>
            {footageRequests.map((request, idx) => (
              <Box
                key={idx}
                component="li"
                sx={{ listStyleType: "none", marginBottom: "8px" }}
              >
                <Box sx={{ display: "flex" }}>
                  <Icon className={classes.leftIcon} title={request.status}>
                    {request.status === "PROGRESSING"
                      ? "cloud"
                      : request.status === "COMPLETE"
                      ? "cloud_done"
                      : request.status === "CANCELED" ||
                        request.status === "ERROR"
                      ? "error"
                      : "cloud_queue"}
                  </Icon>
                  <div>
                    {moment(request.footageStartAt).format("lll")} -{" "}
                    {moment(request.footageEndAt).format("LT")}
                  </div>
                  <Box sx={{ marginLeft: "16px" }}>
                    <InfoPopover request={request} />
                  </Box>
                  {request.status === "PROGRESSING" && (
                    <Box
                      sx={{
                        marginLeft: "16px",
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "8px",
                      }}
                    >
                      <div>
                        <CircularProgress size={14} />
                      </div>
                      <div>
                        <b>{request.jobPercentComplete}%</b> -{" "}
                        <em>
                          {request.phase === "QUERYING" && "Querying device"}
                          {request.phase === "DOWNLOADING" && "Downloading"}
                          {request.phase === "PROCESSING" && "Processing"}
                          {request.phase === "UPLOADING" && "Uploading"}
                        </em>
                      </div>
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </div>
      )}
      {!Boolean(attachedEvidence.length) && <p>No evidence attached</p>}
      <Grid spacing={2} container>
        {onScreenAttachedEvidence.map((attachment, idx) => (
          <Grid
            key={`file-${attachment.source.src}-${idx}`}
            item
            xs={12}
            sm={6}
            md={4}
            xl={3}
          >
            <EvidenceItem
              {...{ attachment, updateEvidence, editable, classes }}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

IncidentEvidence.propTypes = {
  attachedEvidence: PropTypes.array.isRequired,
  showDismissed: PropTypes.bool,
  updateEvidence: PropTypes.func.isRequired,
};

export default IncidentEvidence;
