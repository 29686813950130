import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import UpdateIncident from "../containers/UpdateIncident";
import IncidentPropertyDamage from "../components/IncidentPropertyDamage";
import IncidentPropertyDamageForm from "../components/IncidentPropertyDamageForm";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

const styles = (theme) => ({
  actions: {
    display: "flex",
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonBox: {
    marginLeft: "auto",
  },
});

class IncidentPropertyDamageCard extends React.Component {
  state = {
    editing: false,
    dirtied: false,
    initialFormValues: {
      attachEvidence: {
        evidence: [],
      },
    },
  };

  componentDidMount() {
    this.setState((prev) => ({
      initialFormValues: {
        ...prev.initialFormValues,
        ...this.getInitialFormValuesFrom(this.props.incident),
      },
    }));
  }

  componentDidUpdate() {
    const { dirtied, editing } = this.state;
    const resetInitialise = dirtied && !editing;

    if (resetInitialise)
      this.setState((prev) => ({
        dirtied: false,
        initialFormValues: {
          ...prev.initialFormValues,
          ...this.getInitialFormValuesFrom(this.props.incident),
        },
      }));
  }

  render() {
    const { classes, className, incident, editable } = this.props;
    const { editing, initialFormValues } = this.state;

    let actions = null;
    if (editable && !editing)
      actions = (
        <Button
          variant="text"
          startIcon={<EditIcon />}
          onClick={this.handleEditClick}
        >
          Edit
        </Button>
      );

    return (
      <UpdateIncident
        incidentId={incident.id}
        initialFormValues={initialFormValues}
        onUpdated={this.handleUpdated}
        render={({ handleSubmit, submitting, dirty, reset, values, error }) => (
          <Card className={className}>
            <CardHeader title="Property damage" action={actions} />
            <CardContent>
              {!editing && (
                <IncidentPropertyDamage value={incident.propertyDamage} />
              )}

              {editing && (
                <IncidentPropertyDamageForm value={values.propertyDamage} />
              )}
              {error && <p>{error}</p>}
            </CardContent>
            {editing && (
              <CardActions className={classes.actions} disableSpacing>
                <div className={classes.buttonBox}>
                  <Button
                    aria-label="Cancel"
                    onClick={this.handleCancelEdit(reset)}
                    disabled={submitting}
                    color="primary"
                    className={classes.button}
                  >
                    Cancel
                  </Button>
                  <Button
                    aria-label="Save changes"
                    onClick={handleSubmit}
                    disabled={submitting || !dirty}
                    color="primary"
                    variant="contained"
                    className={classes.button}
                  >
                    Save changes
                  </Button>
                </div>
              </CardActions>
            )}
          </Card>
        )}
      />
    );
  }

  getInitialFormValuesFrom = (incident) => {
    return {
      propertyDamage: {
        detail: incident.propertyDamage.detail,
      },
    };
  };

  handleUpdated = () => {
    this.setState({ editing: false });
  };

  handleEditClick = () => {
    this.setState({ dirtied: true, editing: true });
  };

  handleCancelEdit = (reset) => () => {
    this.setState({ editing: false });
    reset();
  };
}

IncidentPropertyDamageCard.propTypes = {
  incident: PropTypes.object.isRequired,
  editable: PropTypes.bool,
};

export default withStyles(styles)(IncidentPropertyDamageCard);
