import React from "react";
import PropTypes from "prop-types";

import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
import { Icon } from "@mui/material";
import { NavLink } from "react-router-dom";
import OnlineStatusIcon from "../components/OnlineStatusIcon";

const WhichVehicleList = ({
  vehicles,
  searchDate,
  selectedVehicleInstance,
  onVehicleInstanceSelected,
}) => {
  const handleVehicleMarkerClicked = (cursor) => {
    onVehicleInstanceSelected(cursor);
  };

  return (
    <>
      {vehicles?.map((x, idx) => {
        return (
          <List
            key={idx}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                disableGutters
              >
                <ListItemButton
                  component={NavLink}
                  to={`/vehicles/${x.vehicle.id}/replay?date=${searchDate}`}
                >
                  <ListItemIcon>
                    <Icon style={{ color: x.color }}>fiber_manual_record</Icon>
                  </ListItemIcon>
                  <ListItemText primary={x.vehicle.vrm} />
                  <OnlineStatusIcon online={x.vehicle.live.online} />
                </ListItemButton>
              </ListSubheader>
            }
          >
            {x.edges.map((edge) => (
              <ListItemButton
                key={edge.cursor}
                dense
                selected={selectedVehicleInstance === edge.cursor}
                onClick={() => handleVehicleMarkerClicked(edge.cursor)}
              >
                <ListItemText
                  primary={moment(edge.node.timestamp).format("LLL")}
                />
              </ListItemButton>
            ))}
          </List>
        );
      })}
    </>
  );
};

WhichVehicleList.propTypes = {
  onVehicleInstanceSelected: PropTypes.func.isRequired,
  searchDate: PropTypes.string.isRequired,
};

export default WhichVehicleList;
