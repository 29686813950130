import React, { useState, useEffect, useRef, Fragment } from "react";
import makeStyles from "@mui/styles/makeStyles";

import { Field } from "react-final-form";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import TextField from "../components/form/TextField";
import Select from "../components/form/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";
import {
  PENDING_REVIEW,
  ACCEPTED,
  DISMISSED,
  map as evidenceStatusMap,
} from "../EvidenceStatus";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  evidenceMedia: {
    height: 0,
    paddingTop: "200px", // 16:9
  },
  mediaVideo: {
    padding: 0,
  },
  videoCam: {
    fontSize: 60,
    color: theme.palette.divider,
  },
  html5Video: {
    maxWidth: "100%",
  },
  confirmDeleteButton: {
    color: theme.palette.error.main,
  },
  deleteButton: {
    color: theme.palette.action.active,
  },
}));

const IncidentEvidenceItemForm = ({ fieldName, evidence, onRemovedItem }) => {
  const classes = useStyles();
  const [deleteRequested, setDeleteRequested] = useState(false);
  const timerForDeleteConfirmation = useRef(false);

  useEffect(() => {
    return () => clearTimeout(timerForDeleteConfirmation.current);
  }, []);

  const onRequestDelete = () => {
    setDeleteRequested(true);
    timerForDeleteConfirmation.current = setTimeout(() => {
      setDeleteRequested(false);
    }, 2000);
  };

  const onDelete = () => {
    clearTimeout(timerForDeleteConfirmation.current);
    onRemovedItem();
  };

  const actions = (
    <Tooltip title="Delete this evidence">
      {deleteRequested ? (
        <Button className={classes.confirmDeleteButton} onClick={onDelete}>
          Click to confirm
        </Button>
      ) : (
        <Button className={classes.deleteButton} onClick={onRequestDelete}>
          <DeleteIcon />
        </Button>
      )}
    </Tooltip>
  );

  return (
    <Card>
      <CardHeader title="" action={actions} />
      {(evidence || {}).type === "PICTURE" && (
        <CardMedia
          className={classes.evidenceMedia}
          image={evidence.source.src}
        />
      )}
      {(evidence || {}).type === "VIDEO" && (
        <Fragment>
          {(evidence || {}).source.contentType === "video/webm" ||
          (evidence || {}).source.contentType === "video/mp4" ? (
            <CardContent className={classes.mediaVideo}>
              <video className={classes.html5Video} controls>
                Sorry, your browser does not support embedded videos
                <source
                  src={evidence.source.src}
                  type={evidence.source.contentType}
                />
              </video>
            </CardContent>
          ) : (
            <CardContent className={classes.videoDownload}>
              <div>
                <Icon className={classes.videoCam}>videocam</Icon>
              </div>
            </CardContent>
          )}
        </Fragment>
      )}
      <CardContent>
        <Field
          component={Select}
          name={`${fieldName}.status`}
          margin="normal"
          label="Status"
          fullWidth
        >
          <MenuItem value={PENDING_REVIEW}>
            {evidenceStatusMap(PENDING_REVIEW)}
          </MenuItem>
          <MenuItem value={ACCEPTED}>{evidenceStatusMap(ACCEPTED)}</MenuItem>
          <MenuItem value={DISMISSED}>{evidenceStatusMap(DISMISSED)}</MenuItem>
        </Field>
        <Field
          component={TextField}
          name={`${fieldName}.description`}
          margin="normal"
          multiline
          maxRows={3}
          label="Description"
          fullWidth
        />
      </CardContent>
    </Card>
  );
};
export default IncidentEvidenceItemForm;
