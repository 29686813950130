import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import moment from "moment";

import UpdateIncident from "../containers/UpdateIncident";
import IncidentCoreDetails from "../components/IncidentCoreDetails";
import IncidentCoreDetailsForm from "../components/IncidentCoreDetailsForm";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import EditIcon from "@mui/icons-material/Edit";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonBox: {
    marginLeft: "auto",
  },
}));

const IncidentCoreDetailsCard = ({ className, incident, editable }) => {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const [dirtied, setDirtied] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({
    attachEvidence: {
      evidence: [],
    },
  });

  useEffect(() => {
    setInitialFormValues((prev) => ({
      ...prev,
      ...getInitialFormValuesFrom(incident),
    }));
  }, [incident]);

  useEffect(() => {
    if (dirtied && !editing) {
      setDirtied(false);
      setInitialFormValues((prev) => ({
        ...prev,
        ...getInitialFormValuesFrom(incident),
      }));
    }
  }, [dirtied, editing, incident]);

  const getInitialFormValuesFrom = (incident) => ({
    vehicle: {
      vehicleId: incident.vehicleId,
    },
    driver: {
      driverId: incident.driverId,
    },
    incident: {
      incidentDate: moment(incident.occurredAt).format("YYYY-MM-DD"),
      incidentTime: moment(incident.occurredAt).format("HH:mm"),
      location: incident.location,
    },
    account: {
      detailsOfIncident: incident.accountOfIncident || "",
      vehicleSpeed: incident.vehicleSpeed,
    },
  });

  const handleEditClick = () => {
    setDirtied(true);
    setEditing(true);
  };

  const handleCancelEdit = (reset) => () => {
    setEditing(false);
    reset();
  };

  const handleSubmitWrapper = (handleSubmit, form, values) => (ev) => {
    if (values.account.vehicleSpeed) {
      form.mutators.setValue(
        "account.vehicleSpeed",
        Number(values.account.vehicleSpeed)
      );
    }
    handleSubmit(ev);
  };

  const handleUpdated = () => {
    setEditing(false);
  };

  return (
    <UpdateIncident
      incidentId={incident.id}
      initialFormValues={initialFormValues}
      onUpdated={handleUpdated}
      render={({
        handleSubmit,
        submitting,
        dirty,
        form,
        reset,
        values,
        error,
      }) => {
        const actions = editable && !editing && (
          <Button
            variant="text"
            startIcon={<EditIcon />}
            onClick={handleEditClick}
          >
            Edit
          </Button>
        );
        return (
          <Card className={className}>
            <CardHeader title="Details" action={actions} />
            <CardContent>
              {editing ? (
                <IncidentCoreDetailsForm
                  incident={incident}
                  values={values}
                  setFieldValue={form.mutators.setValue}
                />
              ) : (
                <IncidentCoreDetails incident={incident} />
              )}

              {error && <p>{error}</p>}
            </CardContent>
            {editing && (
              <CardActions>
                <div className={classes.buttonBox}>
                  <Button
                    aria-label="Cancel"
                    onClick={() => handleCancelEdit(reset)()}
                    disabled={submitting}
                    color="primary"
                    className={classes.button}
                  >
                    Cancel
                  </Button>
                  <Button
                    aria-label="Save changes"
                    onClick={handleSubmitWrapper(handleSubmit, form, values)}
                    disabled={submitting}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    Save changes
                  </Button>
                </div>
              </CardActions>
            )}
          </Card>
        );
      }}
    />
  );
};

IncidentCoreDetailsCard.propTypes = {
  incident: PropTypes.object.isRequired,
  editable: PropTypes.bool,
};

export default IncidentCoreDetailsCard;
