import React, { useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import qs from "querystringify";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useAuth } from "../AuthContext";

import CenteredPaperLayout from "./CenteredPaperLayout";
import AbovePaperLogo from "../components/AbovePaperLogo";
import userAccountFragment from "../fragments/userAccount";
import oryClient from "../oryClient";
const idFastviewLinkPath =
  process.env.REACT_APP_ID_FV_LINK_URL || "http://localhost:3030/";

const ExchangeSessionTokenMutation = gql`
  mutation ExchangeSessionToken($token: String!) {
    users {
      exchangeSessionToken(token: $token) {
        success
        user {
          id
          name
          email
        }
        account {
          ...userAccount
        }
        token
        expiresIn
      }
    }
  }

  ${userAccountFragment}
`;

const defaultSearchRedirect = (location) => ({
  continue: `${location.pathname}${location.search}`,
});

const RequireAuth = ({
  redirectPathname,
  redirectSearch,
  roles,
  children,
  skipAccountCheck = false,
}) => {
  const [session, setSession] = useState();
  const [exchangeSessionToken] = useMutation(ExchangeSessionTokenMutation);

  const location = useLocation();
  const {
    signIn: setSignIn,
    user,
    isAuthenticated,
    isInRole,
    account,
  } = useAuth();

  useEffect(() => {
    console.log("RequireAuth effect");

    let unmounted = false;
    oryClient
      .toSession({
        tokenizeAs: "jwt_example_template1",
      })
      .then(({ data: sessionData }) => {
        // User has a session!
        console.log("User session", sessionData);
        if (unmounted) return;
        setSession(sessionData);

        const authedUserEmail = user?.email ?? "";
        const sessionUserEmail = sessionData.identity?.traits.email ?? "";
        const sessionEmailAddressesMatch =
          authedUserEmail.localeCompare(sessionUserEmail, undefined, {
            sensitivity: "base",
          }) === 0;

        const authenticatedUserNotChanged =
          isAuthenticated && sessionEmailAddressesMatch;

        console.log("authenticatedUserNotChanged", authenticatedUserNotChanged);

        if (authenticatedUserNotChanged) return;

        return exchangeSessionToken({
          variables: { token: sessionData.tokenized },
        }).then(
          ({
            data: {
              users: { exchangeSessionToken },
            },
          }) => {
            if (unmounted) return;

            console.log("Exchanged session token", exchangeSessionToken);
            if (!exchangeSessionToken.token) {
              throw new Error("Invalid username or password");
            }

            return setSignIn({
              ...exchangeSessionToken,
            });
          }
        );
      })
      .catch((err) => {
        console.error(err);
        window.location.replace(
          `${idFastviewLinkPath}login?returnTo=${encodeURIComponent(
            window.location.origin
          )}`
        );
      });

    return () => (unmounted = true);
  }, []);

  useEffect(() => {
    console.log("session changed", session);
  }, [session]);

  useEffect(() => {
    console.log("isAuthenticated changed", isAuthenticated);
  }, [isAuthenticated]);

  if (!session) {
    return (
      <CenteredPaperLayout
        title="Checking user session"
        above={<AbovePaperLogo />}
      >
        <h1>Checking user session...</h1>
      </CenteredPaperLayout>
    );
  }

  if (!isAuthenticated) {
    return (
      <CenteredPaperLayout title="Authenticating" above={<AbovePaperLogo />}>
        <h1>Authenticating...</h1>
      </CenteredPaperLayout>
    );
  }

  if (!skipAccountCheck && isAuthenticated && account == null) {
    return <Redirect to="/no-accounts" />;
  }

  return isAuthenticated ? (
    isInRole(roles) ? (
      children
    ) : (
      <Redirect
        to={{
          pathname: redirectPathname || "/",
        }}
      />
    )
  ) : (
    <Redirect
      to={{
        pathname: idFastviewLinkPath || "/login",
        search: qs.stringify(
          {
            ...(redirectSearch || defaultSearchRedirect)(location),
          },
          true
        ),
      }}
    />
  );
};

export default RequireAuth;
