import React from "react";
import classNames from "classnames";
import OnlineIcon from "@mui/icons-material/GpsFixed";
import OfflineIcon from "@mui/icons-material/GpsOff";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { red, green } from "@mui/material/colors";

const useStyles = makeStyles(() => ({
  offlineIcon: {
    color: red[500],
  },
  onlineIcon: {
    color: green[500],
  },
}));

const OnlineStatusIcon = ({ online, className }) => {
  const classes = useStyles();

  return online ? (
    <Tooltip title="Vehicle is online">
      <OnlineIcon className={`${classes.onlineIcon} ${className}`} />
    </Tooltip>
  ) : (
    <Tooltip title="Vehicle is offline">
      <OfflineIcon className={`${classes.offlineIcon} ${className}`} />
    </Tooltip>
  );
};

export default OnlineStatusIcon;
