import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { FieldArray } from "react-final-form-arrays";
import Grid from "@mui/material/Grid";
import IncidentEvidenceItemForm from "../components/IncidentEvidenceItemForm";
import IncidentEvidenceUploadForm from "../components/IncidentEvidenceUploadForm";

const IncidentEvidenceForm = ({
  incidentId,
  attachedEvidence,
  newEvidence,
  onConfirm,
}) => (
  <div>
    <Grid spacing={2} container>
      <FieldArray name="evidence.evidence">
        {({ fields }) => (
          <Fragment>
            {fields.map((fieldName, idx) => {
              const file = attachedEvidence[idx];
              return (
                !fields.value[idx].markForDeletion && (
                  <Grid key={`file-${idx}`} item xs={12} sm={6} md={4} xl={3}>
                    <IncidentEvidenceItemForm
                      fieldName={fieldName}
                      value={fields.value[idx]}
                      evidence={file}
                      onConfirm={onConfirm}
                      onRemovedItem={() => {
                        fields.update(idx, {
                          ...fields.value[idx],
                          markForDeletion: true,
                        });
                        onConfirm();
                      }}
                    />
                  </Grid>
                )
              );
            })}
          </Fragment>
        )}
      </FieldArray>
      <IncidentEvidenceUploadForm
        incidentId={incidentId}
        attachEvidence={newEvidence}
      />
    </Grid>
  </div>
);

IncidentEvidenceForm.propTypes = {
  incidentId: PropTypes.string.isRequired,
  newEvidence: PropTypes.object.isRequired,
};

export default IncidentEvidenceForm;
